import React from "react";
import close from "../../images/delete.svg";
import bg from "../../images/Sidebar-bg.svg";
import { Link as LinkScroll } from "react-scroll";

const Sidebar = ({
  isOpen,
  toggle,
  AR,
  item1,
  item2,
  item3,
  item4,
  button,
}) => {
  return (
    <aside
      className={` fixed w-1/2 h-full select-none bg-gradient-to-br from-secondery-300 via-secondery-vibrant to-secondery-600 grid z-50 items-center top-0 transition-all duration-700 ease-in-out
       ${
         AR
           ? isOpen
             ? "opacity-100 left-0"
             : "opacity-0 -left-full"
           : isOpen
           ? "opacity-100 right-0"
           : "opacity-0 -right-full"
       }  `}
      onClick={toggle}
    >
      <div
        className="absolute bg-transparent outline-none cursor-pointer top-5 right-6"
        onClick={toggle}
      >
        <img src={close} alt="close-icon" />
      </div>

      <div className="z-10">
        <ul className="grid grid-cols-1 grid-rows-5 text-xl font-medium text-center text-white gap-7 2xs:text-2xl">
          <LinkScroll
            id="about"
            to="About"
            smooth={true}
            offset={-64}
            onClick={toggle}
            className="transition-colors duration-300 ease-in-out cursor-pointer hover:text-primary-vibrant"
          >
            {item1}
          </LinkScroll>
          <LinkScroll
            id="areas"
            to="Areas"
            smooth={true}
            onClick={toggle}
            className="transition-colors duration-300 ease-in-out cursor-pointer hover:text-primary-vibrant"
          >
            {item2}
          </LinkScroll>
          <LinkScroll
            id="solutions"
            to="solutions"
            smooth={true}
            onClick={toggle}
            className="transition-colors duration-300 ease-in-out cursor-pointer hover:text-primary-vibrant"
          >
            {item3}
          </LinkScroll>
          <LinkScroll
            id="clients"
            to="Clients"
            smooth={true}
            onClick={toggle}
            className="transition-colors duration-300 ease-in-out cursor-pointer hover:text-primary-vibrant"
          >
            {item4}
          </LinkScroll>
        </ul>

        <div className="flex justify-center">
          <LinkScroll
            id="contact"
            to="Contact"
            className="flex items-center justify-center w-32 h-10 text-lg text-center rounded-full cursor-pointer md:w-44 sm:h-14 md:text-xl 1xs:w-40 1xs:h-12 text-richBlack bg-gradient-to-br from-green-500 to-green-400"
            smooth={true}
            offset={15}
            onClick={toggle}
          >
            {button}
          </LinkScroll>
        </div>
      </div>

      {AR ? (
        <a
          id="change to english"
          href="/"
          className="justify-center w-28 bottom-28 left-1/2 -ml-14 text-center absolute font-normal text-3xl z-10 text-platnium font-poppins"
        >
          English
        </a>
      ) : (
        <a
          id="change to arabic"
          href="/ar"
          className="justify-center w-28 bottom-28 left-1/2 -ml-14 text-center absolute font-normal text-3xl z-10 text-platnium font-almarai"
        >
          العربية
        </a>
      )}
      <div className="absolute bottom-0 z-0 flex flex-wrap w-full h-1/2">
        <img
          src={bg}
          alt=""
          className={`absolute bottom-0 z-0 opacity-60 right-1 ${
            AR ? "transform scale-x-mirror " : ""
          } `}
        ></img>
      </div>
    </aside>
  );
};

export default Sidebar;
