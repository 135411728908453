import React from "react";

const Footer = ({ txt, first, second, third }) => {
  return (
    <div id="footer" className="flex flex-row justify-between px-6 py-2 lg:px-12">
      <div className="text-sm self-center text-gray-400 md:text-lg">{txt}</div>
      <div className="grid grid-cols-3 grid-row-1 gap-4 lg:gap-10">
        <a href={first.link} target="_blank" rel="noreferrer">
          <img
            id={first.alt}
            src={require("../../images/" + first.icon)}
            alt={first.alt}
            className="h-7"
          />
        </a>
        <a href={second.link} target="_blank" rel="noreferrer">
          <img
            id={second.alt}
            src={require("../../images/" + second.icon)}
            alt={second.alt}
            className="h-7"
          />
        </a>
        <a href={third.link} target="_blank" rel="noreferrer">
          <img
            id={third.alt}
            src={require("../../images/" + third.icon)}
            alt={third.alt}
            className="h-7"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
