import React from "react";
// import Fade from "react-reveal/Fade";
import { Link as LinkScroll } from "react-scroll";

const HeroSection = ({ title, video, button }) => {
  return (
    <div
      id="Hero"
      className="relative flex justify-center bg-white select-none h-95sc "
    >
      <div className="absolute flex content-center justify-start mt-8 shadow-2xl w-19/20 h-11/12 rounded-3xl">
        <video
          id="hero video"
          className="object-cover w-full h-full rounded-3xl"
          autoPlay={true}
          zindex="0"
          loop
          muted
          src={require("../../videos/" + video)}
          type="video/mp4"
        />
        <div
          className="absolute flex items-center w-full h-full rounded-3xl"
          style={{ background: "#38769185" }}
        >
          <div
            id="HeroContent"
            className="relative flex-col items-start block px-10 h-max w-max max-w-7xl sm:px-14 md:px-20 xl:px-36"
          >
            {/* <Fade bottom> */}
              <div>
                <h1 className="flex pb-10 text-4xl font-bold leading-tight text-transparent bg-gradient-to-br from-platnium to-primary-100 bg-clip-text sm:text-5xl sm:leading-snug md:text-6xl md:leading-snug xl:leading-snug xl:text-8xl ">
                  <>{title}</>
                </h1>

                <div className="flex w-60 ">
                  <LinkScroll
                    id="[hero] contact us"
                    to="Contact"
                    smooth={true}
                    offset={15}
                    className="w-32 h-10 text-xl font-normal leading-loose text-center text-white cursor-pointer rounded-xl bg-gradient-to-r from-primary-vibrant to-secondery-vibrant hover:to-pink-500 hover:from-yellow-500 lg:w-40 lg:h-12 lg:leading-loose lg:text-2xl"
                  >
                    {button}
                  </LinkScroll>
                </div>
              </div>
            {/* </Fade> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
