import React from "react";
import Areas from "./Areas";
import Clients from "./Clients";
import About from "./About";
import Solutions from "./Solutions";
import Partners from "./Partners";

const InfoSection = ({ about, areas, solutions, clients, partners }) => {
  return (
    <>
      <About {...about} />
      <Areas {...areas} />
      <Solutions {...solutions} />
      <Clients {...clients} />
      <Partners {...partners}/>
    </>
  );
};

export default InfoSection;
