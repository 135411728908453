import { Button } from "@chakra-ui/button";
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/input";
import { Alert, AlertIcon, AlertDescription, Collapse } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { FiSend } from "react-icons/fi";
import React from "react";
import * as Yup from "yup";
// import Fade from "react-reveal/Fade";

const ContactSection = ({ info, form, AR }) => {
  const errorMessages = {
    Req: AR ? "مطلوب" : "Required",
    invalidEmail: AR ? "الايميل غير صالح" : "Inavalid email address",
    invalidPN: AR ? "رقم الجوال غير صحيح" : "Phone number is invalid",
    less512: AR
      ? "يجب ان يكون 512 حرف او اقل"
      : "Must be 512 charechters or less",
    less25: AR ? "يجب ان يكون 25 حرف او اقل" : "Must be 25 charechters or less",
    less50: AR ? "يجب ان يكون 50 حرف او اقل" : "Must be 50 charechters or less",
    mustBe9: AR
      ? "رقم الجوال يجب ان يكون 9 ارقام"
      : "Phone number must be 9 numbers",
  };

  // const recaptchaRef = React.createRef();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      pNumber: "",
      company: "",
      message: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .max(25, errorMessages.less25)
        .required(errorMessages.Req),
      email: Yup.string()
        .email(errorMessages.invalidEmail)
        .max(50, errorMessages.less50)
        .required(errorMessages.Req),
      pNumber: Yup.number()
        .max(9999999999, errorMessages.mustBe9)
        .typeError(errorMessages.invalidPN)
        .positive(errorMessages.invalidPN)
        .integer(errorMessages.invalidPN),
      company: Yup.string().max(50, errorMessages.less50),
      message: Yup.string()
        .required(errorMessages.Req)
        .max(512, errorMessages.less512),
    }),

    onSubmit: (values) => {
      // if (captcha) {
      SendEmail(values);
      // } else {
      //   setAlert("error");
      // }
    },
  });

  const [alert, setAlert] = useState(null);
  // const [captcha, setCaptcha] = useState(null);

  // function onChange(value) {
  //   VerifyreCAPTCHA(value);
  // }

  async function SendEmail(params) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImFjYjZiZTUxZWZlYTZhNDE5ZWM5MzI1ZmVhYTFlYzQ2NjBmNWIzN2MiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIzMjU1NTk0MDU1OS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsImF1ZCI6IjMyNTU1OTQwNTU5LmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTEyOTMyOTMxNDgwODI3MjM3NzEzIiwiZW1haWwiOiJmYXJpc2FsaXNzYTFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJfWVZNM0N4TlFZcDVjLUNaeklZamFBIiwiaWF0IjoxNjQ2MDM1MzMzLCJleHAiOjE2NDYwMzg5MzN9.RBYCMRfikhaIZOBCsVOWZdGSDBgKyCIuPAMdoCWxXR2zg0zLm-Z3te90HCM19YDXLnkKI-cBhG9DsRhU06vvny3xZEseVPYTluj_TKfvMCyQcfE2UvqOP5_wbVwASp0oTwPyPOM-PBhQiC1sgO9scrFG0LsnbCw4C-d3yukiSFdm4ZKXJ3R-wbIsNY-mUcmEBWURJUulea4OsuYB82fPzN_GFMcfbikZ2_j87w8BIV3HolBcFJfprHf3WRvhIDDfWnkACVXN75I2dYNElMcPN96q8EsfXo7M8eB1zfbalX-rGcFtyH96XIe0JlO5C3TFiTSqZuyt3aBwO6TyKO532Q"
    );
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
    };
    const url =
      "https://us-central1-layers-website.cloudfunctions.net/sendMail?name=" +
      encodeURIComponent(params.fullName) +
      "&Email=" +
      encodeURIComponent(params.email) +
      "&company=" +
      encodeURIComponent(params.company) +
      "&phone=" +
      encodeURIComponent(params.pNumber) +
      "&message=" +
      encodeURIComponent(params.message);

    await fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          setAlert("success");
          formik.resetForm();
        } else {
          console.log(
            "an error occurred while sending the message HTTP response:" +
              response.status
          );
          setAlert("fail");
        }
      })
      .catch((error) => {
        console.log("an error occurred while sending the message", error);
        setAlert("fail");
      });
  }

  // function VerifyreCAPTCHA(value) {
  //   var requestOptions = {
  //     method: "POST",
  //   };
  //   const secretKey = "6Lchs8UeAAAAADm0v1ScgVSPJKMOMmni_TXqCyVo";
  //   let test =
  //     "https://www.google.com/recaptcha/api/siteverify?secret=" +
  //     secretKey +
  //     "&response=" +
  //     value;
  //   fetch(test, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       setCaptcha(result.success);
  //     })
  //     .catch((error) => setAlert("error"));
  // }

  return (
    <div id="Contact" className="flex justify-center pt-24 min-h-max">
      <div
        id="Contact us"
        className="flex flex-col bg-white border-gray-300 shadow-2xl w-6/7 h-4/5 mb-14 min-h-max min-w-min rounded-2xl lg:flex-row lg:h-90sc lg:w-11/12"
      >
        <div
          id="contact information"
          className="block pt-6 text-white h-2/5 lg:h-full lg:w-2/5 lg:px-12 lg:py-14"
          style={{
            backgroundImage:
              "linear-gradient(125deg, #E1285F 10%, #6366f1 55% , #2B8CB6 90%) ",
          }}
        >
          <h1 className="text-4xl font-semibold select-none px-7 md:text-5xl lg:text-5xl xl:text-6xl 2xl:text-6.5xl">
            {info.title}
          </h1>
          <p className="w-9/12 pt-10 pb-8 text-base font-light text-justify select-none px-7 sm:w-7/12 lg:w-11/12 lg:text-lg lg:pt-20 xl:text-xl 2xl:text-2xl lg:pb-12">
            {info.subTitle}
          </p>
          <div className="grid grid-cols-1 grid-rows-3 gap-4 mb-6 text-base font-light w-max lg:text-lg xl:text-xl 2xl:text-2xl sm:gap-6 ">
            <div className="flex flex-row ">
              <img
                src={require("../../images/" + info.first.icon)}
                alt={info.first.alt}
                className="self-center h-6 px-4 flex-nowrap sm:h-10"
              />
              {info.first.txt}
            </div>
            <div className="flex flex-row">
              <img
                src={require("../../images/" + info.second.icon)}
                alt={info.second.alt}
                className="self-center h-6 px-4 flex-nowrap sm:h-10"
              />
              {info.second.txt}
            </div>
            <div className="flex flex-row">
              <img
                src={require("../../images/" + info.third.icon)}
                alt={info.third.alt}
                className="self-center h-6 px-4 flex-nowrap sm:h-10"
              />
              {info.third.txt}
            </div>
          </div>
        </div>
        <div
          id="form"
          className="relative w-full select-none h-2/3 min-h-max lg:h-full lg:w-2/3 "
        >
          <Collapse endingHeight="12" in={alert}>
            <Alert
              status={alert === "success" ? "success" : "error"}
              roundedTopEnd="2xl"
              paddingLeft="12"
              position="absolute"
              width="full"
            >
              <AlertIcon />
              <AlertDescription>
                {alert === "success"
                  ? `${form.alertMessage.success}`
                  : alert === "error"
                  ? `${form.alertMessage.error}`
                  : alert === "fail"
                  ? `${form.alertMessage.fail}`
                  : ""}
              </AlertDescription>
            </Alert>
          </Collapse>

          <div className="px-8 py-12 lg:px-20 lg:pt-20">
            <h1 className="text-5xl font-light pb-10 xl:text-6xl 2xl:text-6.5xl">
              {form.title}
            </h1>
            <div>
              <form
                id="contact-form"
                name="contact-form"
                className="grid w-full max-w-2xl grid-cols-1 text-xl font-light gap-y-3 gap-x-6 sm:grid-cols-2 lg:text-2xl"
                onSubmit={formik.handleSubmit}
              >
                <div id="name" className="flex flex-col">
                  <label htmlFor="name-input"> {form.input1.label} </label>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    isInvalid={
                      formik.errors.fullName && formik.touched.fullName
                    }
                    id="name-input"
                    name="fullName"
                    autoComplete="name"
                    w="full"
                    h="10"
                    maxLength="26"
                    borderColor="gray.300"
                    errorBorderColor="red.400"
                    placeholder={form.input1.placeholder}
                  />
                  {formik.errors.fullName && formik.touched.fullName ? (
                    // <Fade bottom>
                      <div>
                        <div className="text-base font-light text-red-400">
                          {formik.errors.fullName}
                        </div>
                      </div>
                    // </Fade>
                  ) : null}
                </div>

                <div id="email" className="flex flex-col">
                  <label htmlFor="Email">{form.input2.label}</label>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isInvalid={formik.errors.email && formik.touched.email}
                    id="Email"
                    name="email"
                    autoComplete="email"
                    w="full"
                    maxLength="55"
                    borderColor="gray.300"
                    errorBorderColor="red.400"
                    placeholder={form.input2.placeholder}
                  />
                  {formik.errors.email && formik.touched.email ? (
                    // <Fade bottom>
                      <div>
                        <div className="text-base font-light text-red-400">
                          {formik.errors.email}
                        </div>
                      </div>
                    // </Fade>
                  ) : null}
                </div>
                <div id="pnumber" className="flex flex-col">
                  <label htmlFor="Phone">{form.input3.label}</label>
                  <InputGroup>
                    {AR ? (
                      <InputRightAddon w="16" px="2.5" children="+966" />
                    ) : (
                      <InputLeftAddon w="16" px="2.5" children="+966" />
                    )}

                    <Input
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pNumber}
                      isInvalid={
                        formik.errors.pNumber && formik.touched.pNumber
                      }
                      id="Phone"
                      name="pNumber"
                      type="number"
                      autoComplete="tel-national"
                      w="full"
                      borderColor="gray.300"
                      errorBorderColor="red.400"
                      placeholder={form.input3.placeholder}
                    />
                  </InputGroup>
                  {formik.errors.pNumber && formik.touched.pNumber ? (
                    // <Fade bottom>
                      <div>
                        <div className="text-base font-light text-red-400">
                          {formik.errors.pNumber}
                        </div>
                      </div>
                    // </Fade>
                  ) : null}
                </div>
                <div className="flex flex-col">
                  <label htmlFor="company">{form.input4.label}</label>
                  <Input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company}
                    isInvalid={formik.errors.company && formik.touched.company}
                    id="company"
                    name="company-input"
                    autoComplete="organization"
                    w="full"
                    maxLength="55"
                    borderColor="gray.300"
                    errorBorderColor="red.400"
                    placeholder={form.input4.placeholder}
                  />
                  {formik.errors.company && formik.touched.company ? (
                    // <Fade bottom>
                      <div>
                        <div className="text-base font-light text-red-400">
                          {formik.errors.company}
                        </div>
                      </div>
                    // </Fade>
                  ) : null}
                </div>
                <div className="flex flex-col row-span-3 sm:col-span-2">
                  <label htmlFor="message">{form.input5.label}</label>
                  <textarea
                    id="message"
                    name="message-textbox"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    maxLength="513"
                    rows="2"
                    className={`rounded p-2 h-32 w-full max-h-72 text-lg bg-white min-h-16 mt-1 resize-y border-[1.5px] focus:border-[#3884cc] ${
                      formik.errors.message && formik.touched.message
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  />
                  {formik.errors.message && formik.touched.message ? (
                    // <Fade bottom>
                      <div>
                        <div className="text-base font-light text-red-400">
                          {formik.errors.message}
                        </div>
                      </div>
                    // </Fade>
                  ) : null}
                  {/* <div className="my-8">
                    <ReCAPTCHA
                      name="reCAPTCHA"
                      ref={recaptchaRef}
                      size="small"
                      sitekey="6Lchs8UeAAAAAGClhmQG4cQebyrlqI8OvxWxFqaQ"
                      onChange={onChange}
                      hl={AR ? "ar" : "en"}
                    />
                  </div> */}
                  <Button
                    id="form-submit"
                    leftIcon={<FiSend />}
                    colorScheme="blue"
                    mt={4}
                    width="28"
                    type="submit"
                  >
                    {form.button}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
