import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";

export const NavLinks = styled(LinkScroll)`
  color: #387691;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 2rem;
  margin: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #387691;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 0.5rem;
    font-size: 18px;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    color: #39a3d0;
  }
`;
