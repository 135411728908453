import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import TagManager from "react-gtm-module";
import Home from "./pages";
import Arabic from "./pages/arabic";

const tagManagerArgs = {
  gtmId: "GTM-5JZXR6L",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/ar" component={Arabic} exact />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </ChakraProvider>
  );
}

export default App;