import React, { useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import ContactSection from "../components/ContactSection/Index";
import Footer from "../components/Footer/Index";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection/Index";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import {
  footer,
  contactSection,
  heroSection,
  infoSection,
  navBar,
} from "../Data-Arabic";

const tagManagerArgs = {
  dataLayer: {
    page: "arabic",
  },
  dataLayerName: "PageDataLayer",
};

const Arabic = () => {
  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  document.title = "المستويات السبع لتقنية المعلومات";

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ fontFamily: "Almarai", direction: "rtl" }}>
      <Sidebar {...navBar} isOpen={isOpen} toggle={toggle} AR={true} />
      <Navbar {...navBar} toggle={toggle} AR={true} />
      <HeroSection {...heroSection} />
      <InfoSection {...infoSection} />
      <ContactSection {...contactSection} AR={true} />
      <Footer {...footer} />
    </div>
  );
};

export default Arabic;
