export const navBar = {
  item1: "About",
  item2: "Areas",
  item3: "Solutions",
  item4: "Clients",
  button: "Contact Us",
};

export const heroSection = {
  title: (
    <>
      {" "}
      Welcome to. <br /> Seven Layers IT.{" "}
    </>
  ),
  video: "video.mp4",
  button: "Contact Us",
};

export const infoSection = {
  about: {
    title: "ABOUT US",
    desc: "Seven Layers Information Technology is a leading provider of IT Services and Advanced Solutions since 2001 in the Kingdom of Saudi Arabia, delivering perfection-driven client solutions that meet the strategic objectives of our clients. Seven Layers is committed to providing professional services and advanced solutions that assist our enterprise customers in Information Technology and Telecom services. Our services are met on time, within budget, high quality, greater efficiency, responsiveness, and continuous growth to your business.",
  },
  areas: {
    // To change any icon, place the new icon in 'src\images\Areas' then write the new icon's name with extension exactly as is in the 'icon' property
    // And change the alt property to any text that descripes the new image
    // any other property can be changed to any text
    /* For Example:
                  Areas4: {
                      icon: "camera.svg",
                      alt:"Camera",
                      title: "Security Systems",
                      desc: "We have a great experince in installing managing and maintaining security system including CCTV, Access Control",
            }, */

    MainTitle: "Our Areas",
    Area1: {
      img: "networking.svg",
      alt: "networking",
      title: "Networking",
      desc: "From computers and printers to firewalls, our team has great breadth and depth of knowledge when it comes to creating solid, industry-proven solutions for businesses of all sizes.",
    },
    Area2: {
      img: "video-wall.svg",
      alt: "Video Wall",
      title: "Digital Sinage",
      desc: "Complete suite of Digital Signage Software and Hardware solutions that allow you to easily develop and deploy enterprise and standalone digital signage solutions.",
    },
    Area3: {
      img: "vc.svg",
      alt: "Video Conference",
      title: "Video Conference",
      desc: "It’s important to keep your business running even when key people can’t be in the same place at the same time. Video conferencing is about just that – collaboration and human connection, regardless of distance.",
    },
    Area4: {
      img: "camera.svg",
      alt: "Camera",
      title: "Security Systems",
      desc: "Our flexible design ensures the rapid integration of access control, time & attendance, elevator control, video surveillance via a common platform.",
    },
  },
  solutions: {
    // To change any image, place the new image in 'src\images\solutions' then write the new image's name with extension exactly as is in the 'imgName' property
    // change the alt property to any text that descripes the image
    /* For Example:
                  image1: {
                      imgName: "server-room.png",
                      alt:"server room"
            }, */
    title: "OUR SOLUTIONS",
    image1: { imgName: "1.jpg", alt: "amc lobby" },
    image2: { imgName: "2.jpg", alt: "amc screens" },
    image3: { imgName: "3.jpeg", alt: "amc kiosk" },
    image4: { imgName: "4.jpeg", alt: "amc videowall" },
    image5: { imgName: "8.jpg", alt: "EL&N menu tvs" },
    image6: { imgName: "5.jpg", alt: "EL&N car tv" },
    image7: { imgName: "6.jpg", alt: "EL&N outside tv" },
    image8: { imgName: "7.jpg", alt: "rack" },
  },
  clients: {
    // To change any image, place the new image in 'src\images' then write the new image's name with extension exactly as is in the 'imgName' property
    // change the alt property to any text that descripes the image
    /* For Example:
                  Client1: {
                      imgName: "Moda.png",
                      alt:"Ministry of Defense"
            }, */

    title: "We are proudly trusted by",
    Client1: {
      img: "moda.svg",
      alt: "Ministry of Defense",
    },
    Client2: {
      img: "moe.svg",
      alt: "Ministry of Education",
    },
    Client3: {
      img: "kacst.svg",
      alt: "King Abdulaziz City for Science and Technology",
    },
    Client4: {
      img: "kfshrc.jpg",
      alt: "king faisal specialist hospital & research centre",
    },
    Client5: {
      img: "sef.svg",
      alt: "Saudi Esports Federation",
    },
    Client6: {
      img: "EL&N.webp",
      alt: "EL&N London cafe",
    },
    Client7: {
      img: "amc.png",
      alt: "AMC Cinemas",
    },
    Client8: {
      img: "seven.png",
      alt: "Saudi Entertainment Ventures",
    },
  },
  partners: {
    // To change any image, place the new image in 'src\images' then write the new image's name with extension exactly as is in the 'imgName' property
    // change the alt property to any text that descripes the image
    /* For Example:
            Partner1: {
                imgName: "Moda.png",
                alt:"Ministry of Defense"
      }, */
    title: "Gladly working with",
    Partner1: {
      img: "Fortinet.svg",
      alt: "Fortinet",
    },
    Partner2: {
      img: "Dell_EMC.svg",
      alt: "Dell EMC",
    },
    Partner3: {
      img: "commscope.svg",
      alt: "CommScope",
    },
    Partner4: {
      img: "pads4.png",
      alt: "Pads4",
    },
    Partner5: {
      img: "Barco.jpeg",
      alt: "Barco",
    },
    Partner6: {
      img: "Nakivo.png",
      alt: "Nakivo",
    },
    Partner7: {
      img: "APC.svg",
      alt: "APC",
    },
    Partner8: {
      img: "STC.svg",
      alt: "STC",
    },
  },
};

export const contactSection = {
  info: {
    title: (
      <>
        {" "}
        Contact <br /> Information.{" "}
      </>
    ),
    subTitle:
      "We will be glad to hear from you for any enquiries or questions.",
    first: {
      icon: "marker.svg",
      alt: "location",
      txt: "Riyadh 12211, Saudi Arabia.",
    },
    second: {
      icon: "phone.svg",
      alt: "phone",
      txt: "+966 11 2165775",
    },
    third: {
      icon: "email.svg",
      alt: "email",
      txt: "info@7-layers.com",
    },
  },
  form: {
    title: "Send us a message",
    alertMessage: {
      success: "Thank you for contacting us, We will get back to you shortly",
      error: "Please complete the reCAPTCHA before submitting",
      fail: "Sorry, an unexpected error occurred while sending your message",
    },
    input1: {
      label: "Name:",
      placeholder: "John Doe",
    },
    input2: {
      label: "Email:",
      placeholder: "John@company.com",
    },
    input3: {
      label: "Phone Number:",
      placeholder: "500000000",
    },
    input4: {
      label: "Company:",
      placeholder: "Company Inc.",
    },
    input5: {
      label: "Message",
    },
    button: "Send",
  },
};

export const footer = {
  txt: "© 2022 Seven Layers IT Solutions.",
  first: {
    icon: "twitter.svg",
    alt: "twitter",
    link: "https://twitter.com/SevenLayersKSA/",
  },
  second: {
    icon: "facebook.svg",
    alt: "facebook",
    link: "https://www.facebook.com/sevenlayersit/",
  },
  third: {
    icon: "linkedin.svg",
    alt: "linkedin",
    link: "https://www.linkedin.com/company/seven-layers-information-technology/",
  },
};