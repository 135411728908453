import React from "react";
// import Fade from "react-reveal/Fade";

const Partners = ({
  title,
  Partner1,
  Partner2,
  Partner3,
  Partner4,
  Partner5,
  Partner6,
  Partner7,
  Partner8,
}) => {
  return (
    <div
      id="Partners"
      className="flex flex-col bg-white justify-center pt-24 items-center select-none"
    >
      <h1 className="text-center text-gray-400 text-md text-3xl font-light pb-12 px-4">
        {title}
      </h1>
      <div
        className="grid w-7/12 items-center grid-cols-2 gap-x-10 gap-y-8 sm:w-6/12 md:w-6/12 lg:w-9/12 xl:w-7/12 lg:px-0 lg:grid-cols-4 lg:gap-x-24 "
        // style={{ width: "fit-content" }}
      >
          <img
            id={Partner1.img}
            src={require("../../images/Partners/" + Partner1.img)}
            alt={Partner1.alt}
          ></img>
          <img
            id={Partner2.img}
            src={require("../../images/Partners/" + Partner2.img)}
            alt={Partner2.alt}
          ></img>
          <img
            id={Partner3.img}
            src={require("../../images/Partners/" + Partner3.img)}
            alt={Partner3.alt}
          ></img>
          <img
            id={Partner4.img}
            src={require("../../images/Partners/" + Partner4.img)}
            alt={Partner4.alt}
          ></img>
          <img
            className="justify-self-center lg:w-[39.66%] lg:col-span-2"
            id={Partner5.img}
            src={require("../../images/Partners/" + Partner5.img)}
            alt={Partner5.alt}
          ></img>
          <img
            className="justify-self-center lg:w-[39.66%] lg:col-span-2"
            id={Partner6.img}
            src={require("../../images/Partners/" + Partner6.img)}
            alt={Partner6.alt}
          ></img>
          {/* <img
            id={Partner7.img}
            src={require("../../images/Partners/" + Partner7.img)}
            alt={Partner7.alt}
          ></img>
          <img
            id={Partner8.img}
            src={require("../../images/Partners/" + Partner8.img)}
            alt={Partner8.alt}
          ></img> */}
      </div>
    </div>
  );
};

export default Partners;