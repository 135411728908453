import React, { useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import ContactSection from "../components/ContactSection/Index";
import Footer from "../components/Footer/Index";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection/Index";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import {
  footer,
  contactSection,
  heroSection,
  infoSection,
  navBar,
} from "../Data";

const tagManagerArgs = {
  dataLayer: {
    page: "English",
  },
  dataLayerName: "PageDataLayer",
};

const Home = () => {
  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  document.title = "Seven Layers IT";

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <Sidebar {...navBar} isOpen={isOpen} toggle={toggle} AR={false} />
      <Navbar {...navBar} toggle={toggle} AR={false} />
      <HeroSection {...heroSection} />
      <InfoSection {...infoSection} />
      <ContactSection {...contactSection} AR={false} />
      <Footer {...footer} />
    </div>
  );
};

export default Home;
