import React from "react";
// import Fade from "react-reveal/Fade";

const Clients = ({
  title,
  Client1,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
  Client8,
}) => {
  return (
    <div
      id="Clients"
      className="flex flex-col bg-white justify-center pt-24 items-center select-none "
    >
      <h1 className="text-center text-gray-400 text-md text-3xl font-light pb-12 px-4">
        {title}
      </h1>
      <div
        className="grid w-7/12 items-center grid-cols-2 gap-x-10 gap-y-8 sm:w-6/12 md:w-6/12 lg:w-9/12 xl:w-7/12 lg:px-0 lg:grid-cols-4 lg:gap-x-24 "
        // style={{ width: "fit-content" }}
      >
        {/* <Fade left> */}
          <img
            id={Client1.img}
            src={require("../../images/Clients/" + Client1.img)}
            alt={Client1.alt}
          ></img>
          <img
            id={Client2.img}
            src={require("../../images/Clients/" + Client2.img)}
            alt={Client2.alt}
          ></img>
          <img
            id={Client3.img}
            src={require("../../images/Clients/" + Client3.img)}
            alt={Client3.alt}
          ></img>
          <img
            id={Client4.img}
            src={require("../../images/Clients/" + Client4.img)}
            alt={Client4.alt}
          ></img>
        {/* </Fade> */}
        {/* <Fade right> */}
          <img
            id={Client5.img}
            src={require("../../images/Clients/" + Client5.img)}
            alt={Client5.alt}
          ></img>
          <img
            id={Client6.img}
            src={require("../../images/Clients/" + Client6.img)}
            alt={Client6.alt}
          ></img>
          <img
            id={Client7.img}
            src={require("../../images/Clients/" + Client7.img)}
            alt={Client7.alt}
          ></img>
          <img
            id={Client8.img}
            src={require("../../images/Clients/" + Client8.img)}
            alt={Client8.alt}
          ></img>
        {/* </Fade> */}
      </div>
    </div>
  );
};

export default Clients;
