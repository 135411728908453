import React from "react";
// import Fade from "react-reveal/Fade";
import bgImg from "../../images/About-bg.svg";

const About = ({ title, desc }) => {
  return (
    <div
      id="About"
      className="w-full h-auto px-10 pt-20 pb-16 bg-no-repeat bg-cover text-center text-white text-md text-5xl font-semibold 2xl:px-64 xl:px-44 lg:px-24 lg:pb-24 md:px-14 md:pb-20"
      style={{
        backgroundImage: "url(" + bgImg + ")",
      }}
    >
      {title}
      {/* <Fade bottom cascade> */}
        <div>
          <p
            id="about txt"
            className="md:text-2xl pt-16 text-xl font-extralight text-center"
          >
            {desc}
          </p>
        </div>
      {/* </Fade> */}
    </div>
  );
};

export default About;
